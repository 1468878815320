import styled from 'styled-components';

import { ButtonPrimary, ButtonThird } from 'components/Button';
import { ReactComponent as LogoWallet } from 'assets/images-app/wallet.svg';
import { ReactComponent as LogoNear } from 'assets/images-app/near.svg';
import { ReactComponent as ArrowDown } from 'assets/images-app/icon-arrow-down.svg';
import { trimAccountId } from 'utils';
import { EModals, useModalContext } from 'providers/modals-provider';
import { useWalletSelector } from 'providers/wallet-provider';
import { EDimensions } from 'utils/constants';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';

const Wallet = styled(LogoWallet)`
  margin-right: 0.625rem;
`;

const Near = styled(LogoNear)`
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
`;

const MobileNear = styled(LogoNear)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;

const Arrow = styled(ArrowDown)`
  margin-left: .453rem;
  width: 12px;
  height: 8px;
`;

const MobileArrow = styled(ArrowDown)`
  width: .594rem;
  height: .344rem;
  margin-left: .453rem;
`;

const PinkButtonThird = styled(ButtonThird)`
  background-color: ${({ theme }) => theme.pink};
  border-color: ${({ theme }) => theme.pink};
`;

const NewButtonThird = styled(ButtonThird)`
  padding: .25rem;
`;

export default function ConnectionButton() {
  const { accountId, isSignedIn, openModal } = useWalletSelector();
  const isConnected = isSignedIn();
  const { showModal } = useModalContext();
  const dimension = useWindowDimensions();
  const { t } = useTranslation();
  const isMobile = dimension === EDimensions.SMALL || dimension === EDimensions.EXTRA_SMALL;
  const title = isConnected && accountId ? trimAccountId(accountId) : t('action.connectWallet');

  if (isConnected) {
    return (
      <NewButtonThird onClick={() => showModal(EModals.ACCOUNT_MODAL, {})}>
        {isMobile ? <><MobileNear /><MobileArrow /></> : <><Near />{title}<Arrow /></>}
      </NewButtonThird>
    );
  }
  return (
    <>
      {isMobile
        ? (
          <PinkButtonThird onClick={openModal}>
            <Wallet />
            <MobileArrow />
          </PinkButtonThird>
        )
        : (
          <ButtonPrimary onClick={openModal}>
            <Wallet />
            {title}
          </ButtonPrimary>
        ) }
    </>
  );
}
