import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${({ theme }) => theme.appBackground};
`;

export const Body = styled.div`
  flex: 1;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
`;

export const LoadingBlock = styled.div<{ isApp?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, isApp }) => (isApp ? '' : theme.landingBackground)};
  min-height: ${({ isApp }) => (isApp ? '' : '100vh')};
  width: 100%;
`;
