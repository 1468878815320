import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';
import { ReactComponent as NearIcon } from 'assets/images-app/near-icon.svg';
import { ButtonSecondary } from './styles';

const StyledButton = styled(ButtonSecondary)<{ isMenuList?: boolean }>`
  border-color: ${({ theme }) => theme.greenBorder};
  gap: 0.5rem;
  :hover {
    background: rgba(${({ theme }) => theme.greenBgRGB}, 0.05);
    border-color: ${({ theme }) => theme.greenBorderHover};
  }
  :active {
    background: rgba(${({ theme }) => theme.greenBgRGB}, 0.15);
    border-color: ${({ theme }) => theme.greenBorderActive};
  }

  ${({ isMenuList }) => isMenuList && css`
    padding: .376rem .751rem;
    border-radius: 0.375rem;
    font-weight: 700;
    font-size: .875rem;
    line-height: 1rem;
    width: 100%;
    justify-content: flex-start;
    color: ${({ theme }) => theme.globalGrey};
    gap: 0;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
    svg {
      path {
          fill: ${({ theme }) => theme.globalGrey};
      }
    }
    :hover {
      color: ${({ theme }) => theme.globalWhite};
      svg {
        path {
            fill: ${({ theme }) => theme.globalWhite};
        }
      }
    }
  `}
`;

export default function BuyNearButton(
  {
    handler,
    isMenuList,
  }: {
    handler: () => void,
    isMenuList?: boolean
  },
) {
  return (
    <StyledButton onClick={handler} isMenuList={isMenuList}>
      <div>
        <NearIcon />
      </div>
      <Trans i18nKey="action.buyNEAR" />
    </StyledButton>
  );
}
