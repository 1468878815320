import { Suspense, lazy } from 'react';
import Footer from 'components/Footer';
import GifLoading from 'assets/gif/loading.gif';

import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import {
  ALL_MATCH,
  POOL,
  RETRODROP,
  SWAP,
  SWAP_FUTURES,
  STAKING,
} from 'utils/routes';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import SwapFutures from 'pages/SwapFutures';
import Retrodrop from 'pages/Retrodrop';
import { useWalletSelector } from 'providers/wallet-provider';
import Staking from 'pages/Staking';
import Header from 'components/Header';
import useTransactionHash from 'hooks/useTransactionHash';
import {
  Container,
  Body,
  LoadingBlock,
} from './styles';

const Swap = lazy(() => import('pages/Swap'));
const Pool = lazy(() => import('pages/Pool'));
const Error = lazy(() => import('pages/Error'));

export default function App() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { accountId } = useWalletSelector();
  useTransactionHash(search, accountId);

  return (
    <Container>
      <Header />
      <Body>
        <Suspense fallback={(
          <LoadingBlock isApp>
            <img src={GifLoading} alt={t('common.loading')} />
          </LoadingBlock>
              )}
        >
          <Routes>
            <Route path={SWAP} element={<Swap />} />
            <Route path={SWAP_FUTURES} element={<SwapFutures />} />
            <Route path={RETRODROP} element={<Retrodrop />} />
            <Route path={`${POOL}/*`} element={<Pool />} />
            <Route path={`${STAKING}/*`} element={<Staking />} />
            <Route path={ALL_MATCH} element={<Error />} />
          </Routes>
        </Suspense>
      </Body>
      <Footer />
      <ToastContainer />
    </Container>
  );
}
