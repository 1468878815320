import { css, DefaultTheme } from 'styled-components/macro';
import { MEDIA_WIDTHS } from 'utils/constants';

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

export const colors = {
  layoutBlack: 'rgba(0, 0, 0, 0.6)',

  globalBlack: '#131313',
  globalWhite: '#FFFFFF',
  globalGrey: '#8991A3',
  globalGreen: '#84DA18',

  globalGreyOp04: '#4A515F',
  globalGreyOp02: '#353B49',
  globalGreyOp01: '#2A313D',

  globalGreen0p02: '#2B3F20',

  black: '#000000',
  white: '#ffffff',

  yellowHapi: '#FDEE2D',
  greyText: '#91949D',
  greyCard: '#5A5E6D',
  greyCardShadow: 'rgba(19, 19, 19, 0.6)',
  greyFooterLogo: '#6A6D76',
  greySocialNetworkBg: '#2B2B2B',
  greyBorder: '#727272',
  blackCardBg: 'rgba(19, 19, 19, 0.9)',
  blackCardText: '#A1A4AC',
  blackCardShadow: '#41444d',
  redBorder: '#FE2C55',
  darkGreenBg: '#212C1E;',
  greenText: '#8EF46A',

  pink: '#FE2C55',
  pinkHover: '#E12243',
  pinkActive: '#C71A35',
  darkPink: '#722136',

  greyButton: '#454D5C',
  claimButton: '#151922',
  claimListBg: '#2A303C',

  backgroundCard: '#202632',
  boxShadowCard: 'rgba(10, 12, 18, 0.2)',
  specialBorderCard: 'linear-gradient(180deg, rgb(53, 60, 73), rgba(137, 145, 163, 0) 100%)',
  error: '#FF6F6F',
  tooltip: '#454D5C',

  warningBg: '#4C2739',
  bgToken: '#dfe2e8',

  farmingBg: '#2E253E',
  rewardTokensBg: '#201B2C',
  farmingTimeBg: '#373048',

  statusActive: '#84DA18',
  statusActiveOp01: '#26332A',
  statusPending: '#F0B237',
  statusPendingOp01: '#31302D',
  statusEnded: '#8991A3',
  statusFarmInPoolBg: '#1C212C',
  menuBg: '#2A303C',
  appBackground: '#151922',
  greenBorder: '#26EA99',
  greenBorderHover: '#02D27C',
  greenBorderActive: '#00AE61',
  greenBgRGB: '38, 234, 153',
  claimBackground: '#171C25',
  toastBg: '#2A303C',
};

const landingColors = {
  landingBackground: '#151922',
  headerBorder: 'rgba(184, 184, 191, 0.2)',
  greyHapi: '#B8B8BF',
  grey: '#A1A4AC',
  borderNear: '#363639',
  borderInvestors: '#3A3A3D',
  divisionInvestors: 'linear-gradient(180deg, rgba(90, 90, 93, 0) 0%, #5A5A5D 52.08%, rgba(90, 90, 93, 0) 100%)',
  divisionInvestorsMobile: 'linear-gradient(90deg, rgba(90, 90, 93, 0) 0%, #5A5A5D 52.08%, rgba(90, 90, 93, 0) 100%)',
  listTasks: '#481E27',
  partnersBorder: '#363638',
  pitchTalkBg: '#2A2244',
  disabledLink: '#515256',
  headerListHover: '#333A46',
};

function theme(): DefaultTheme {
  return {
    ...colors,
    ...landingColors,

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default theme;
