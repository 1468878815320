import { IFarm } from 'store';
import i18n from 'i18n';
import Big from 'big.js';
import { secondsToMilliseconds } from 'utils/calculationDate';
import { isNotNullOrUndefined } from 'utils';

export enum farmStatus {
  created = 'Created',
  running = 'Running',
  ended = 'Ended',
}

export enum FarmStatusEnum {
  Active = 'Active',
  Pending = 'Pending',
  Ended = 'Ended'
}

export const getFarmStatus = (
  status: string,
  dateStart: number,
): FarmStatusEnum => {
  const currentDate = Date.now();
  const startDate = secondsToMilliseconds(dateStart);

  if (status === farmStatus.ended) return FarmStatusEnum.Ended;

  if (startDate > currentDate) return FarmStatusEnum.Pending;
  if (currentDate > startDate && status === farmStatus.running) return FarmStatusEnum.Active;
  return FarmStatusEnum.Ended;
};

export const FarmStatusLocales = {
  [FarmStatusEnum.Active]: i18n.t('farm.status.active'),
  [FarmStatusEnum.Pending]: i18n.t('farm.status.pending'),
  [FarmStatusEnum.Ended]: i18n.t('farm.status.ended'),
};
export const FarmStatusLocalesInPool = {
  [FarmStatusEnum.Active]: i18n.t('farm.status.available'),
  [FarmStatusEnum.Pending]: i18n.t('farm.status.pending'),
  [FarmStatusEnum.Ended]: '',
};
export const FarmStatusLocalesInYourPool = {
  [FarmStatusEnum.Active]: i18n.t('farm.status.started'),
  [FarmStatusEnum.Pending]: i18n.t('farm.status.soon'),
  [FarmStatusEnum.Ended]: '',
};

interface IPoolTimestampByFarms {
  farmStart: number,
  farmEnd: number,
  timeToStart: number,
  status: FarmStatusEnum,
}

const getFarmStart = (farms: IFarm[]) => {
  const farmStarts: number[] = farms.map((farm) => farm.startAt);
  const farmStart = Math.min(...farmStarts);
  return secondsToMilliseconds(farmStart);
};

const getFarmEnd = (farms: IFarm[]) => {
  const farmEnds = farms.map((farm) => {
    if (Big(farm.rewardPerSession).gt(0)) {
      const farmDuration = Big(farm.sessionInterval)
        .times(farm.totalReward)
        .div(farm.rewardPerSession)
        .toNumber();
      return farm.startAt + farmDuration;
    }
    return null;
  }).filter(isNotNullOrUndefined);
  const farmEnd = farmEnds.length ? Math.round(Math.max(...farmEnds)) : 0;
  return secondsToMilliseconds(farmEnd);
};

const getTimeToStart = (farms: IFarm[], currentDate: number) => {
  const timeToStarts = farms.map((farm) => {
    const formattedStartAt = secondsToMilliseconds(farm.startAt);
    if (formattedStartAt > currentDate && farm.status === FarmStatusEnum.Pending) {
      return farm.startAt;
    }
    return null;
  }).filter(isNotNullOrUndefined);
  const timeToStart = timeToStarts.length ? Math.min(...timeToStarts) : 0;
  return secondsToMilliseconds(timeToStart);
};

const getPoolStatusByFarms = (farmStart: number, farmEnd: number, currentDate: number) => {
  if (farmStart > currentDate) return FarmStatusEnum.Pending;
  if (currentDate > farmStart && currentDate < farmEnd) return FarmStatusEnum.Active;
  return FarmStatusEnum.Ended;
};

export const getPoolTimestampByFarms = (farms: IFarm[]): IPoolTimestampByFarms => {
  if (farms.length === 0) {
    return {
      farmStart: 0,
      farmEnd: 0,
      timeToStart: 0,
      status: FarmStatusEnum.Ended,
    };
  }
  const currentDate = Date.now();

  const farmStart = getFarmStart(farms);
  const farmEnd = getFarmEnd(farms);
  const timeToStart = getTimeToStart(farms, currentDate);
  const status = getPoolStatusByFarms(farmStart, farmEnd, currentDate);

  return {
    farmStart,
    farmEnd,
    timeToStart,
    status,
  };
};
