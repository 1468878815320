import type { LinkProps } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as JumboLogo } from 'assets/images-app/jumbo-logo.svg';
import Menu from 'components/Menu';
import {
  LANDING,
  POOL,
  SWAP,
  STAKING,
} from 'utils/routes';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { EDimensions } from 'utils/constants';
import BuyNearButton from 'components/Button/BuyNear';
import { useWalletSelector } from 'providers/wallet-provider';
import { openUTORGLink } from 'utils';
import {
  Container,
  LogoContainer,
  LogoTitle,
  NavBar,
  NavButton,
  BlockButton,
  LinkContainer,
} from './styles';
import ConnectionButton from './ConnectionButton';

function CustomLink({
  children, to,
}: LinkProps) {
  const location = useLocation();
  const isActive = location.pathname.includes(to.toString());
  return (
    <Link to={to}>
      <NavButton isActive={isActive}>
        {children}
      </NavButton>
    </Link>
  );
}

export default function Header() {
  const { accountId } = useWalletSelector();
  const { t } = useTranslation();
  const dimension = useWindowDimensions();
  const isMobile = dimension === EDimensions.SMALL || dimension === EDimensions.EXTRA_SMALL;
  const hideBuyNear = isMobile || dimension === EDimensions.MEDIUM;
  return (
    <Container>
      <LinkContainer>
        <Link to={LANDING}>
          <LogoContainer>
            <JumboLogo />
            {isMobile ? null : (<LogoTitle>{t('jumbo')}</LogoTitle>)}
          </LogoContainer>
        </Link>
      </LinkContainer>
      <NavBar>
        <CustomLink to={SWAP}>
          {t('general.swap')}
        </CustomLink>
        <CustomLink to={POOL}>
          {t('general.pool')}
        </CustomLink>
        <CustomLink to={STAKING}>
          {t('general.staking')}
        </CustomLink>
      </NavBar>
      <BlockButton>
        {!hideBuyNear && <BuyNearButton handler={() => openUTORGLink(accountId)} />}
        <ConnectionButton />
      </BlockButton>
      <Menu />
    </Container>
  );
}
